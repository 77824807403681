import { Outlet, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Sidebar from './Sidebar';
import { useEffect } from 'react';
import { isAppAuthenticated } from '../../services/tokens';

function Layout() {
	const navigate = useNavigate();

	useEffect(() => {
		if (!isAppAuthenticated()) {
			navigate('/');
		}
	}, [navigate]);

	if (isAppAuthenticated()) {
		return (
			<LayoutStyle>
				<Sidebar />
				<main>
					<Outlet />
				</main>
			</LayoutStyle>
		);
	}

	return null;
}

export default Layout;

const LayoutStyle = styled.div`
	position: fixed;
	width: 100%;
	height: 100vh;
	display: grid;
	grid-template-columns: minmax(200px, auto) 1fr;
	grid-template-rows: 1fr;

	& main {
		padding: 20px;
		overflow-y: auto;
		max-width: 1000px;
	}
`;
