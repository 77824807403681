import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './Router';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import reduxStore from './services/redux-store';
import { ThemeProvider } from 'styled-components';
import { ConfigProvider, theme as antdTheme } from 'antd';
import { darkMode } from './utitlities/theme';
import { globalStyle as GlobalStyle } from './utitlities/theme';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(
	<React.StrictMode>
		<Provider store={reduxStore}>
			<QueryClientProvider client={queryClient}>
				<ThemeProvider theme={darkMode}>
					<ConfigProvider
						theme={{ token: {}, algorithm: antdTheme.darkAlgorithm }}
					>
						<GlobalStyle />
						<App />
					</ConfigProvider>
				</ThemeProvider>
			</QueryClientProvider>
		</Provider>
	</React.StrictMode>
);
