import { Button, Card, Divider, Input, Space } from 'antd';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import { checkInstallation, login } from '../../services/endpoints';
import { useEffect, useState } from 'react';
import SetupAdmin from './SetupAdmin';
import { useNavigate } from 'react-router-dom';
import { isAppAuthenticated } from '../../services/tokens';

function Login() {
	const navigate = useNavigate();

	useEffect(() => {
		if (isAppAuthenticated()) {
			return navigate('/overview');
		}
	}, [navigate]);

	const [displayPage, setDisplayPage] = useState('none');
	const { mutate: submit, isLoading } = useMutation(login, {
		onSuccess: () => {
			navigate('/overview');
		},
	});

	const formik = useFormik({
		initialValues: {
			email: '',
			password: '',
		},
		onSubmit: (values) => {
			submit(values);
		},
	});

	useEffect(() => {
		setDisplayPage('loading');
		checkInstallation().then((d) => {
			if (d.isInstalled) {
				setDisplayPage('login');
			} else {
				setDisplayPage('setup');
			}
		});
	}, []);

	if (displayPage === 'login') {
		return (
			<LoginLayout>
				<main>
					<Card>
						<form onSubmit={formik.handleSubmit}>
							<Space direction="vertical" style={{ width: '100%' }}>
								<h1>Admin Login</h1>
								<Divider />
								<Input
									value={formik.values.email}
									type="email"
									size="large"
									placeholder="Email"
									name="email"
									onChange={formik.handleChange}
								/>
								<Input.Password
									size="large"
									value={formik.values.password}
									onChange={formik.handleChange}
									name="password"
									placeholder="Password"
								/>
								<Button
									disabled={isLoading}
									loading={isLoading}
									htmlType="submit"
									block
									size="large"
									type="primary"
								>
									Login
								</Button>
							</Space>
						</form>
					</Card>
				</main>
			</LoginLayout>
		);
	}

	if (displayPage === 'setup') {
		return <SetupAdmin />;
	}

	return null;
}

export default Login;

export const LoginLayout = styled.div`
	display: flex;
	align-items: center;
	height: 100vh;

	& main {
		min-height: 300px;
		width: 100%;
		max-width: 500px;
		margin-left: auto;
		margin-right: auto;
	}
`;
