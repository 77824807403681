import { useMutation, useQuery } from 'react-query';
import { useAppSelector } from '../../services/redux-store';
import {
	getSubscriptionPackages,
	patchInternalSettings,
	postSubscriptionPackages,
} from '../../services/endpoints';
import {
	Button,
	Divider,
	Input,
	Modal,
	Popconfirm,
	Select,
	Space,
	Table,
	Tag,
	message,
} from 'antd';
import { assignKeysToItems } from '../../utitlities/general';
import { PlusOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { useFormik } from 'formik';

const Subscription = () => {
	const { settings } = useAppSelector((store) => store.settings);

	const { data } = useQuery('subscription-packages', () =>
		getSubscriptionPackages()
	);

	return (
		<>
			<Space direction="vertical" size={20} style={{ width: '100%' }}>
				<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
					<AddSubscriptionPackage />
				</div>
				<Table
					columns={[
						{ title: 'ID', dataIndex: 'id' },
						{
							title: 'Name',
							dataIndex: 'name',
							render: (name, row) => (
								<>
									<p>{name}</p>
									{row.id === settings?.defaultSubscription?.id && (
										<Tag>Default</Tag>
									)}
								</>
							),
						},
						{ title: 'Price', dataIndex: 'price' },
						{
							title: 'Currency',
							dataIndex: 'currencyId',
							render: (val) => val.symbol,
						},
						{
							title: '',
							dataIndex: 'id',
							render: (id) => (
								<>
									{id !== settings?.defaultSubscription?.id && (
										<MakeDefaultSubscription
											settingsId={settings.id}
											subscriptionId={id}
										/>
									)}
								</>
							),
						},
					]}
					dataSource={assignKeysToItems(data?.items)}
				/>
			</Space>
		</>
	);
};

export default Subscription;

const AddSubscriptionPackage = () => {
	const [open, setOpen] = useState(false);

	const { currencies } = useAppSelector((store) => store.settings);
	const { mutate } = useMutation(postSubscriptionPackages, {
		onSuccess: () => {
			message.success('Subscription package created!');
			setOpen(false);
			formik.resetForm();
		},
	});

	const formik = useFormik({
		initialValues: {
			name: '',
			price: 0,
			currencyId: '',
		},
		onSubmit: (values) => {
			mutate(values);
		},
	});

	return (
		<>
			<Button onClick={() => setOpen(true)} icon={<PlusOutlined />}>
				Add
			</Button>

			<Modal
				open={open}
				footer={null}
				title="Subscription Package"
				onCancel={() => setOpen(false)}
				width={320}
			>
				<Divider />
				<form onSubmit={formik.handleSubmit}>
					<Space direction="vertical" style={{ width: '100%' }}>
						<div>
							<p>Name</p>
							<Input
								value={formik.values.name}
								onChange={formik.handleChange}
								name="name"
							/>
						</div>
						<div>
							<p>Price</p>
							<Input
								value={formik.values.price}
								name="price"
								onChange={formik.handleChange}
							/>
						</div>
						<div>
							<p>Currency</p>
							<Select
								onChange={(e) => formik.setFieldValue('currencyId', e)}
								style={{ width: '100%' }}
								options={(currencies || []).map((x: any) => ({
									label: x.symbol,
									value: x.id,
								}))}
							/>
						</div>
						<br />
						<div>
							<Button htmlType="submit" block type="primary">
								Submit
							</Button>
						</div>
					</Space>
				</form>
			</Modal>
		</>
	);
};

const MakeDefaultSubscription = ({
	subscriptionId,
	settingsId,
}: {
	settingsId: any;
	subscriptionId: any;
}) => {
	const { mutate: updateSettings } = useMutation(patchInternalSettings, {
		onSuccess: () => {
			message.success('Settings updated');
		},
	});

	return (
		<Popconfirm
			title="Are you sure?"
			onConfirm={() =>
				updateSettings({
					id: settingsId,
					updates: { defaultSubscriptionPackageId: subscriptionId },
				})
			}
		>
			<Button size="small">Set Default</Button>
		</Popconfirm>
	);
};
