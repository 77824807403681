import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './pages/login';
import Overview from './pages/overview';
import Settings from './pages/settings';
import Layout from './components/layout/Layout';
import Laboratory from './pages/laboratory';
import SingleLaboratory from './pages/laboratory/single-lab';
import LaboratoryMembers from './pages/laboratory/single-lab/Members';
import MyProfile from './pages/profile';
import LaboratoryPatients from './pages/laboratory/single-lab/Patients';

function RouterConfig() {
	return (
		<Router>
			<Routes>
				<Route path="/" element={<Login />} />
			</Routes>
			<Routes>
				<Route element={<Layout />}>
					<Route path="/overview" element={<Overview />} />
					<Route path="/profile" element={<MyProfile />} />
					<Route path="/settings" element={<Settings />} />
					<Route path="/settings/:activeTab" element={<Settings />} />
					<Route path="/lab" element={<Laboratory />} />
					<Route path="/lab/:id" element={<SingleLaboratory />} />
					<Route path="/lab/:id/members" element={<LaboratoryMembers />} />
					<Route path="/lab/:id/patients" element={<LaboratoryPatients />} />
				</Route>
			</Routes>
		</Router>
	);
}

export default RouterConfig;
