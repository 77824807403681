import { Divider, Table, Tag } from 'antd';
import { useMutation } from 'react-query';
import { getLaboratories } from '../../services/endpoints';
import { useEffect } from 'react';
import { assignKeysToItems, remakeQueryString } from '../../utitlities/general';
import { Link, useSearchParams } from 'react-router-dom';

function Laboratory() {
	const { mutate, data, isLoading } = useMutation(getLaboratories, {});

	const [searchParams, setSearchParams] = useSearchParams();

	const updateQueryString = (query: any, action = 'update') => {
		remakeQueryString(query, searchParams, setSearchParams, action);
	};

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length === 0) {
			setSearchParams({
				page: '1',
				pageSize: '10',
			});
		}
	}, []);

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length > 0) {
			mutate(queryData);
		}
	}, [searchParams]);

	return (
		<div>
			<h1>Laboratories {data && `(${data?.totalItems})`}</h1>
			<Divider />
			<Table
				loading={isLoading}
				dataSource={assignKeysToItems(data?.items)}
				columns={[
					{ title: 'ID', dataIndex: 'id' },
					{
						title: 'Name',
						dataIndex: 'name',
						render: (name, row) => (
							<div>
								<Link to={`/lab/${row?.id}`}>{name}</Link>{' '}
								{row.isBranch && <Tag>Branch</Tag>}
							</div>
						),
					},
					{
						title: 'Domain',
						dataIndex: 'defaultDomain',
					},
					{
						title: 'Current Subscription',
						dataIndex: 'currentSubscription',
						render: (currentSub) => <>{currentSub?.name}</>,
					},
				]}
				pagination={{
					pageSize: data?.pageSize || 10,
					current: Number(data?.currentPage || 1),
					total: Number(data?.totalItems),
					onChange(page, pageSize) {
						updateQueryString({ pageSize, page });
					},
				}}
			/>
		</div>
	);
}

export default Laboratory;
