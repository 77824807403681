import { Divider, Table } from 'antd';
import { useMutation, useQuery } from 'react-query';
import { useParams, useSearchParams } from 'react-router-dom';
import {
	getLaboratoryMembers,
	getSingleLaboratory,
} from '../../../services/endpoints';
import { useEffect } from 'react';
import {
	assignKeysToItems,
	remakeQueryString,
} from '../../../utitlities/general';

function LaboratoryMembers() {
	const { id } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();

	const { data: labInfo } = useQuery('single-lab', () =>
		getSingleLaboratory(Number(id))
	);

	const {
		isLoading,
		mutate: getMembersList,
		data,
	} = useMutation(getLaboratoryMembers, {});

	const updateQueryString = (query: any, action = 'update') => {
		remakeQueryString(query, searchParams, setSearchParams, action);
	};

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length === 0) {
			setSearchParams({
				page: '1',
				pageSize: '10',
			});
		}
	}, []);

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length > 0) {
			getMembersList({ params: queryData, id: Number(id) });
		}
	}, [searchParams]);

	return (
		<div>
			<h1>[Members] {labInfo?.name}</h1>
			<Divider />
			<Table
				loading={isLoading}
				dataSource={assignKeysToItems(data?.items)}
				columns={[
					{ title: 'ID', dataIndex: 'id' },
					{
						title: 'Name',
						dataIndex: 'staff',
						render: (staff, row) => (
							<>
								<p>
									{staff.firstName} {staff.lastName}
								</p>
								<p>{row.email}</p>
							</>
						),
					},
					{
						title: 'Title',
						dataIndex: 'title',
					},
					{
						title: 'Role',
						dataIndex: 'role',
						render: (role) => role?.name,
					},
				]}
				pagination={{
					pageSize: data?.pageSize || 10,
					current: Number(data?.currentPage || 1),
					total: Number(data?.totalItems),
					onChange(page, pageSize) {
						updateQueryString({ pageSize, page });
					},
				}}
			/>
		</div>
	);
}

export default LaboratoryMembers;
