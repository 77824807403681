import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { deleteToken } from '../../services/tokens';

function MyProfile() {
	const navigate = useNavigate();
	return (
		<div>
			<Button
				onClick={() => {
					deleteToken();
					navigate('/');
				}}
			>
				Logout
			</Button>
		</div>
	);
}

export default MyProfile;
