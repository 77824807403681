const tokenName = '20-h-932-e';
const appSettings = '0-h-app-settings';

interface IAppSettings {
	theme?: string;
}

export const retrieveToken = (): string | null => {
	return localStorage.getItem(tokenName);
};

export const saveToken = (data: string) => {
	localStorage.setItem(tokenName, data);
};

export const deleteToken = () => {
	localStorage.removeItem(tokenName);
};

export const isAppAuthenticated = (): boolean => {
	const token = retrieveToken();
	return !!token;
};

export const retrieveSettings = (): IAppSettings | null => {
	const data = localStorage.getItem(appSettings);
	if (data) {
		return JSON.parse(data);
	}
	return null;
};

export const saveSettings = (data: IAppSettings) => {
	let a = {};
	const settings = localStorage.getItem(appSettings);
	if (settings) {
		a = JSON.parse(settings);
	}
	localStorage.setItem(appSettings, JSON.stringify({ ...a, ...data }));
};
