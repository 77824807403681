import { Divider, Table } from 'antd';
import { useMutation, useQuery } from 'react-query';
import { useParams, useSearchParams } from 'react-router-dom';
import {
	getLaboratoryPatients,
	getSingleLaboratory,
} from '../../../services/endpoints';
import { useEffect } from 'react';
import {
	assignKeysToItems,
	remakeQueryString,
} from '../../../utitlities/general';

function LaboratoryPatients() {
	const { id } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();

	const { data: labInfo } = useQuery('single-lab', () =>
		getSingleLaboratory(Number(id))
	);

	const {
		isLoading,
		mutate: getPatientsList,
		data,
	} = useMutation(getLaboratoryPatients, {});

	const updateQueryString = (query: any, action = 'update') => {
		remakeQueryString(query, searchParams, setSearchParams, action);
	};

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length === 0) {
			setSearchParams({
				page: '1',
				pageSize: '10',
			});
		}
	}, []);

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length > 0) {
			getPatientsList({ params: queryData, id: Number(id) });
		}
	}, [searchParams]);

	return (
		<div>
			<h1>[Patients] {labInfo?.name}</h1>
			<Divider />
			<Table
				loading={isLoading}
				dataSource={assignKeysToItems(data?.items)}
				columns={[
					{ title: 'ID', dataIndex: 'id' },
					{
						title: 'Name',
						dataIndex: 'firstName',
						render: (firstName, row) => (
							<>
								<p>
									{firstName} {row.lastName}
								</p>
								<p>{row.email}</p>
							</>
						),
					},
				]}
				pagination={{
					pageSize: data?.pageSize || 10,
					current: Number(data?.currentPage || 1),
					total: Number(data?.totalItems),
					onChange(page, pageSize) {
						updateQueryString({ pageSize, page });
					},
				}}
			/>
		</div>
	);
}

export default LaboratoryPatients;
