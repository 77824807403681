import { Button, Divider, Switch, message } from 'antd';
import { useAppSelector } from '../../services/redux-store';
import { EditOutlined } from '@ant-design/icons';
import { useMutation } from 'react-query';
import { patchInternalSettings } from '../../services/endpoints';
import { useNavigate } from 'react-router-dom';

const General = ({ fetchSettings }: { fetchSettings: Function }) => {
	const navigate = useNavigate();

	const { settings } = useAppSelector((store) => store.settings);

	const { mutate: updateSettings } = useMutation(patchInternalSettings, {
		onSuccess: () => {
			fetchSettings();
			message.success('Settings updated');
		},
	});

	return (
		<>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<p>Allow Registration</p>
				<div>
					<Switch
						checked={settings?.allowRegistration}
						onChange={(e) =>
							updateSettings({
								id: settings.id,
								updates: { allowRegistration: e },
							})
						}
					/>
				</div>
			</div>
			<Divider />
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<p>Default Subscription</p>
				<div>
					<p style={{ fontWeight: 'bold' }}>
						{settings?.defaultSubscription?.name}{' '}
						<Button
							size="small"
							type="text"
							icon={<EditOutlined />}
							onClick={() => navigate('/settings/subscription')}
						/>
					</p>
				</div>
			</div>
			<Divider />
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<p>Default Organisation Creator&apos;s Role</p>
				<div>
					<p style={{ fontWeight: 'bold' }}>
						{settings?.defaultOrganisationCreatorRole?.name}{' '}
						<Button
							size="small"
							type="text"
							icon={<EditOutlined />}
							onClick={() => navigate('/settings/roles')}
						/>
					</p>
				</div>
			</div>
		</>
	);
};

export default General;
