import { createSlice } from '@reduxjs/toolkit';

const settings = createSlice({
	name: 'settings',
	initialState: {
		currencies: [],
		settings: {
			id: 0,
			isActive: false,
			allowRegistration: false,
			defaultOrganisationCreatorRole: {
				id: 0,
				name: '',
			},
			defaultSubscription: {
				id: 0,
				name: '',
				price: 0,
				currencyId: {
					id: 1,
					name: '',
					symbol: '',
					isActive: false,
					lastUpdatedBy: {
						id: 0,
						email: '',
						firstName: '',
						lastName: null,
						role: {
							id: 0,
							name: '',
						},
					},
				},
				lastUpdatedById: {
					id: 0,
					email: '',
					firstName: '',
					lastName: null,
					role: {
						id: 0,
						name: '',
					},
				},
			},
		},
	},
	reducers: {
		updateSettings: (store, action) => {
			store.settings = action.payload;
		},
		updateCurrencies: (store, action) => {
			store.currencies = action.payload;
		},
	},
});

export const { updateSettings, updateCurrencies } = settings.actions;

export default settings.reducer;
