import { useMutation } from 'react-query';
import { getAdminUsers, postAdminUsers } from '../../services/endpoints';
import { Button, Divider, Input, message, Modal, Space, Table } from 'antd';
import { assignKeysToItems } from '../../utitlities/general';
import { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { useFormik } from 'formik';

function AdminTeam() {
	const { mutate: retrieveAdministrators, data } = useMutation(getAdminUsers);

	useEffect(() => {
		retrieveAdministrators();
	}, []);

	return (
		<Space direction="vertical" style={{ width: '100%' }}>
			<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
				<AddNewAdminMember />
			</div>

			<Table
				dataSource={assignKeysToItems(data?.items)}
				columns={[
					{ title: 'ID', dataIndex: 'id' },
					{ title: 'Firstname', dataIndex: 'firstName' },
					{ title: 'Lastname', dataIndex: 'lastName' },
					{ title: 'Email', dataIndex: 'email' },
					{ title: 'Role', dataIndex: 'role', render: (role) => role?.name },
				]}
			/>
		</Space>
	);
}

export default AdminTeam;

const AddNewAdminMember = () => {
	const [open, setOpen] = useState(false);

	const { mutate, isLoading } = useMutation(postAdminUsers, {
		onSuccess: () => {
			setOpen(false);
			message.success('Team member added');
		},
	});

	const formik = useFormik({
		initialValues: {
			firstName: '',
			lastName: '',
			email: '',
			password: '',
			roleId: '',
		},
		onSubmit: (values) => {
			mutate(values);
		},
	});

	return (
		<>
			<Button icon={<PlusOutlined />} onClick={() => setOpen(true)}>
				Add
			</Button>

			<Modal
				open={open}
				onCancel={() => setOpen(false)}
				title="Add New Team Member"
				footer={null}
				confirmLoading={isLoading}
			>
				<Divider />
				<form onSubmit={formik.handleSubmit}>
					<Space direction="vertical" style={{ width: '100%' }}>
						<div>
							<p>Email</p>
							<Input
								type="email"
								value={formik.values.email}
								onChange={formik.handleChange}
								name="email"
							/>
						</div>
						<div>
							<p>First Name</p>
							<Input
								name="firstName"
								value={formik.values.firstName}
								onChange={formik.handleChange}
							/>
						</div>
						<div>
							<p>Last Name</p>
							<Input
								value={formik.values.lastName}
								onChange={formik.handleChange}
								name="lastName"
							/>
						</div>
						<div>
							<p>Password</p>
							<Input
								value={formik.values.password}
								onChange={formik.handleChange}
								name="password"
							/>
						</div>
						<div>
							<p>Role ID</p>
							<Input
								value={formik.values.roleId}
								onChange={formik.handleChange}
								name="roleId"
							/>
						</div>
						<div>
							<Button
								loading={isLoading}
								disabled={isLoading}
								type="primary"
								block
								size="large"
								htmlType="submit"
							>
								Submit
							</Button>
						</div>
					</Space>
				</form>
			</Modal>
		</>
	);
};
