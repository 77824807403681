import baseuri from './base-uri';
import { saveToken } from './tokens';

export const setup = async (data: any) => {
	const res = await baseuri.post('/v1/admin-users/setup', data);
	return res.data;
};

export const login = async (data: any) => {
	const res = await baseuri.post('/v1/admin-users/auth', data);
	saveToken(res.data);
	return res.data;
};

export const checkInstallation = async () => {
	const res = await baseuri.get('/v1/data/installation');
	return res.data;
};

export const getInternalSettings = async () => {
	const res = await baseuri.get('/v1/app-settings');
	return res.data;
};

export const patchInternalSettings = async ({
	id,
	updates,
}: {
	id: number;
	updates: any;
}) => {
	const res = await baseuri.patch(`/v1/app-settings/${id}`, updates);
	return res.data;
};

export const getSubscriptionPackages = async () => {
	const res = await baseuri.get('/v1/subscription-packages');
	return res.data;
};

export const postSubscriptionPackages = async (data: any) => {
	const res = await baseuri.post('/v1/subscription-packages', data);
	return res.data;
};

export const getCurrencies = async () => {
	const res = await baseuri.get('/v1/currencies');
	return res.data;
};

export const postCurrencies = async (data: any) => {
	const res = await baseuri.post('/v1/currencies', data);
	return res.data;
};

export const getLaboratories = async (params: any) => {
	const res = await baseuri.get('/v1/organisations', { params });
	return res.data;
};

export const getSingleLaboratory = async (id: number) => {
	const res = await baseuri.get(`/v1/organisations/${id}`);
	return res.data;
};

export const getLaboratoryMembers = async ({
	id,
	params,
}: {
	id: number;
	params: any;
}) => {
	const res = await baseuri.get(`/v1/organisations/${id}/members`, { params });
	return res.data;
};

export const getLaboratoryPatients = async ({
	id,
	params,
}: {
	id: number;
	params: any;
}) => {
	const res = await baseuri.get(`/v1/organisations/${id}/patients`, { params });
	return res.data;
};

export const getOrganisationRoles = async () => {
	const res = await baseuri.get('/v1/organisation-roles');
	return res.data;
};

export const postOrganisationRoles = async (data: any) => {
	const res = await baseuri.post('/v1/organisation-roles', data);
	return res.data;
};

export const getAdminUsers = async () => {
	const res = await baseuri.get('/v1/admin-users');
	return res.data;
};

export const postAdminUsers = async (data: any) => {
	const res = await baseuri.post('/v1/admin-users', data);
	return res.data;
};
