import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import {
	getLaboratoryMembers,
	getLaboratoryPatients,
	getSingleLaboratory,
} from '../../../services/endpoints';
import { Divider, Tag } from 'antd';

function SingleLaboratory() {
	const { id } = useParams();

	const { data: labInfo } = useQuery('single-lab', () =>
		getSingleLaboratory(Number(id))
	);

	const { data: members } = useQuery('members', () =>
		getLaboratoryMembers({ id: Number(id), params: { page: 1, pageSize: 1 } })
	);

	const { data: patients } = useQuery('patients', () =>
		getLaboratoryPatients({ id: Number(id), params: { page: 1, pageSize: 1 } })
	);

	useEffect(() => {
		//
	}, [id]);

	return (
		<div>
			<h1>{labInfo?.name}</h1>
			<div>
				<a
					href={`https://${labInfo?.defaultDomain}.prometalab.com`}
					rel="noreferrer noopener"
					target="_blank"
				>
					{labInfo?.defaultDomain}.prometalab.com
				</a>
			</div>
			<Divider />
			<Tag>
				<Link to={`./branches`}>0 Branches</Link>
			</Tag>
			<Tag>
				<Link to={`./members`}>{members?.totalItems} Members</Link>
			</Tag>
			<Tag>
				<Link to={`./patients`}>{patients?.totalItems} Patients</Link>
			</Tag>
		</div>
	);
}

export default SingleLaboratory;
