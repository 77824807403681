import axios from 'axios';
import { retrieveToken } from './tokens';

const baseuri = axios.create({
	// eslint-disable-next-line no-undef
	baseURL: process.env.REACT_APP_BASE,
});

baseuri.interceptors.request.use((config) => {
	const token = retrieveToken();

	if (token) {
		config.headers['Authorization'] = `Bearer ${token}`;
	}

	return config;
});

export default baseuri;
