import { useQuery } from 'react-query';
import { getCurrencies, getInternalSettings } from '../../services/endpoints';
import { Tabs } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import Subscription from './Subscription';
import { useAppDispatch } from '../../services/redux-store';
import {
	updateCurrencies,
	updateSettings,
} from '../../services/reducers/settings';
import General from './General';
import Roles from './Roles';
import AdminTeam from './Admins';
import Currencies from './Currencies';

function Settings() {
	const dispatch = useAppDispatch();

	const navigate = useNavigate();
	const params = useParams();

	const { refetch: refetchSettings } = useQuery(
		'settings',
		() => getInternalSettings(),
		{
			retry: 0,
			onSuccess: (data) => {
				dispatch(updateSettings(data));
			},
		}
	);
	useQuery('currencies', () => getCurrencies(), {
		retry: 0,
		refetchOnMount: false,
		onSuccess: (data) => {
			dispatch(updateCurrencies(data.items));
		},
	});

	const tabItems: any[] = [
		{
			key: 'general',
			label: 'General',
			children: <General fetchSettings={refetchSettings} />,
		},
		{
			key: 'currencies',
			label: 'Currencies',
			children: <Currencies />,
		},
		{
			key: 'subscription',
			label: 'Subscription',
			children: <Subscription />,
		},
		{
			key: 'roles',
			label: 'Roles',
			children: <Roles />,
		},
		{
			key: 'team',
			label: 'Team',
			children: <AdminTeam />,
		},
	];

	const getActiveTab = () => {
		if (!Object.keys(params).includes('activeTab')) {
			return 'general';
		}

		if (!tabItems.map((x) => x.key).includes(params.activeTab)) {
			return 'general';
		}

		return params.activeTab;
	};

	return (
		<div>
			<h1>Settings</h1>

			<Tabs
				activeKey={getActiveTab()}
				items={[...tabItems]}
				onChange={(tab) => navigate(`/settings/${tab}`)}
			/>
		</div>
	);
}

export default Settings;
