import { Link } from 'react-router-dom';
import styled from 'styled-components';

function Sidebar() {
	return (
		<Style>
			<div>
				<p>Admin Panel</p>
			</div>
			<div>
				<div>
					<Link to="/overview">Overview</Link>
				</div>
				<div>
					<Link to="/lab">Laboratories</Link>
				</div>
				<div>
					<Link to="/settings">Settings</Link>
				</div>
				<div>
					<Link to="/profile">My Profile</Link>
				</div>
			</div>
		</Style>
	);
}

export default Sidebar;

const Style = styled.aside`
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: minmax(150px, 10%) 1fr;

	& > div {
		padding: 20px;
	}

	& > div:last-child {
		display: flex;
		flex-direction: column;
		padding: 20px;

		& a {
			color: white;
			display: inline-block;
			padding: 10px 20px;

			&:hover {
				color: #00cc66;
			}
		}
	}
`;
