import {
	Button,
	Divider,
	Input,
	Modal,
	Space,
	Switch,
	Table,
	message,
} from 'antd';
import { useState } from 'react';
import { postCurrencies } from '../../services/endpoints';
import { useMutation } from 'react-query';
import { useFormik } from 'formik';
import { PlusOutlined } from '@ant-design/icons';
import { useAppSelector } from '../../services/redux-store';
import { assignKeysToItems } from '../../utitlities/general';

function Currencies() {
	const { currencies } = useAppSelector((store) => store.settings);
	return (
		<div>
			<Space direction="vertical" style={{ width: '100%' }}>
				<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
					<AddCurrency />
				</div>
				<Table
					dataSource={assignKeysToItems(currencies)}
					columns={[
						{ title: 'ID', dataIndex: 'id' },
						{ title: 'Name', dataIndex: 'name' },
						{ title: 'Symbol', dataIndex: 'symbol' },
						{
							title: 'Active',
							dataIndex: 'isActive',
							render: (active) => (active ? 'TRUE' : 'FALSE'),
						},
					]}
				/>
			</Space>
		</div>
	);
}

export default Currencies;

const AddCurrency = () => {
	const [open, setOpen] = useState(false);

	const { mutate, isLoading } = useMutation(postCurrencies, {
		onSuccess: () => {
			message.success('Subscription package created!');
			setOpen(false);
			formik.resetForm();
		},
	});

	const formik = useFormik({
		initialValues: {
			name: '',
			symbol: '',
			isActive: false,
		},
		onSubmit: (values) => {
			mutate(values);
		},
	});

	return (
		<>
			<Button onClick={() => setOpen(true)} icon={<PlusOutlined />}>
				Add
			</Button>

			<Modal
				confirmLoading={isLoading}
				open={open}
				footer={null}
				title="Currency"
				onCancel={() => setOpen(false)}
				width={320}
			>
				<Divider />
				<form onSubmit={formik.handleSubmit}>
					<Space direction="vertical" style={{ width: '100%' }}>
						<div>
							<p>Name</p>
							<Input
								value={formik.values.name}
								onChange={formik.handleChange}
								name="name"
							/>
						</div>
						<div>
							<p>Symbol</p>
							<Input
								value={formik.values.symbol}
								name="symbol"
								onChange={formik.handleChange}
							/>
						</div>
						<div>
							<p>Active</p>
							<Switch
								checked={formik.values.isActive}
								onChange={(e) => formik.setFieldValue('isActive', e)}
							/>
						</div>
						<br />
						<div>
							<Button
								loading={isLoading}
								disabled={isLoading}
								htmlType="submit"
								block
								type="primary"
							>
								Submit
							</Button>
						</div>
					</Space>
				</form>
			</Modal>
		</>
	);
};
