import { useMutation } from 'react-query';
import {
	getOrganisationRoles,
	patchInternalSettings,
	postOrganisationRoles,
} from '../../services/endpoints';
import { useEffect, useState } from 'react';
import {
	Button,
	Divider,
	Input,
	Modal,
	Popconfirm,
	Space,
	Table,
	message,
} from 'antd';
import { assignKeysToItems } from '../../utitlities/general';
import { PlusOutlined } from '@ant-design/icons';
import { useFormik } from 'formik';
import { useAppSelector } from '../../services/redux-store';

function Roles() {
	const { settings } = useAppSelector((store) => store.settings);
	const { mutate, data: orgRoles } = useMutation(getOrganisationRoles, {});

	useEffect(() => {
		mutate();
	}, []);

	return (
		<div>
			<Space direction="vertical" style={{ width: '100%' }}>
				<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
					<CreateOrganisationRole />
				</div>
				<Table
					dataSource={assignKeysToItems(orgRoles?.items)}
					columns={[
						{ title: 'ID', dataIndex: 'id' },
						{
							title: 'Name',
							dataIndex: 'name',
							render: (name, row) => (
								<>
									<p>{name}</p>
									{Number(row.id) !==
										Number(settings.defaultOrganisationCreatorRole?.id) && (
										<MakeDefaultCreatorRole
											roleId={row.id}
											settingsId={settings.id}
										/>
									)}
								</>
							),
						},
					]}
				/>
			</Space>
		</div>
	);
}

export default Roles;

const MakeDefaultCreatorRole = ({
	roleId,
	settingsId,
}: {
	settingsId: any;
	roleId: any;
}) => {
	const { mutate: updateSettings } = useMutation(patchInternalSettings, {
		onSuccess: () => {
			message.success('Settings updated');
		},
	});

	return (
		<Popconfirm
			title="Are you sure?"
			onConfirm={() =>
				updateSettings({
					id: settingsId,
					updates: { defaultOrganisationCreatorRoleId: roleId },
				})
			}
		>
			<Button size="small">Set Default</Button>
		</Popconfirm>
	);
};

const CreateOrganisationRole = () => {
	const [open, setOpen] = useState(false);

	const { mutate: submitOrgRole } = useMutation(postOrganisationRoles, {
		onSuccess: () => {
			setOpen(false);
			message.success('Role added');
		},
	});

	const formik = useFormik({
		initialValues: {
			name: '',
		},
		onSubmit: (values) => {
			submitOrgRole(values);
		},
	});

	return (
		<>
			<Button onClick={() => setOpen(true)} icon={<PlusOutlined />}>
				Add
			</Button>

			<Modal
				open={open}
				onCancel={() => setOpen(false)}
				title="Create Organisation Role"
				footer={null}
				width={320}
			>
				<Divider />
				<form onSubmit={formik.handleSubmit}>
					<div>
						<p>Name</p>
						<Input
							value={formik.values.name}
							onChange={formik.handleChange}
							name="name"
						/>
					</div>
					<br />
					<div>
						<Button type="primary" block htmlType="submit">
							Create
						</Button>
					</div>
				</form>
			</Modal>
		</>
	);
};
