import { Divider } from 'antd';

function Overview() {
	return (
		<div>
			<h1>Overview</h1>
			<Divider />
		</div>
	);
}

export default Overview;
