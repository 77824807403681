import { Card, Divider, Space, Input, Button, message } from 'antd';
import { LoginLayout } from '.';
import { useMutation } from 'react-query';
import { setup } from '../../services/endpoints';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';

function SetupAdmin() {
	const navigate = useNavigate();

	const { mutate: submit, isLoading } = useMutation(setup, {
		onSuccess: () => {
			message.success('Setup Complete!');
			navigate('/overview');
		},
	});

	const formik = useFormik({
		initialValues: {
			email: '',
			password: '',
			firstName: '',
			lastName: '',
		},
		onSubmit: (values) => {
			submit(values);
		},
	});
	return (
		<LoginLayout>
			<main>
				<Card>
					<form onSubmit={formik.handleSubmit}>
						<Space direction="vertical" style={{ width: '100%' }}>
							<h1>Setup Admin</h1>
							<Divider />
							<Input
								value={formik.values.email}
								type="email"
								size="large"
								placeholder="Email"
								name="email"
								onChange={formik.handleChange}
							/>
							<Input
								value={formik.values.firstName}
								type="firstName"
								size="large"
								placeholder="First Name"
								name="firstName"
								onChange={formik.handleChange}
							/>
							<Input
								value={formik.values.lastName}
								type="lastName"
								size="large"
								placeholder="Last Name"
								name="lastName"
								onChange={formik.handleChange}
							/>
							<Input.Password
								size="large"
								value={formik.values.password}
								onChange={formik.handleChange}
								name="password"
								placeholder="Password"
							/>
							<Button
								disabled={isLoading}
								loading={isLoading}
								htmlType="submit"
								block
								size="large"
								type="primary"
							>
								Submit
							</Button>
						</Space>
					</form>
				</Card>
			</main>
		</LoginLayout>
	);
}

export default SetupAdmin;
